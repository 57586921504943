/* --------------- */
/* GENERAL SECTION */
/* --------------- */
html {
	scroll-behavior: smooth;
    /* cursor: url('/backend/frontend/public/assets/cursor.png'), auto; */
}

html, body {
    font-size: 16px;
}

::selection {
    color: #111111;
    background-color: #dd8500;
}

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #eeeeee;
}

main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 1rem;
    margin: 0 auto;
    min-height: 500px;
}

h2 {
    margin: 0;
    padding-left: 5px;
}

hr {
    border: 1px solid black;
}

.wrapper {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: center;
    width: 100%;
}

#noThumbnail {
    flex-direction: column;
    margin-left: 45%;
}

.Announcments {
    width: calc(100% - 22px);
    height: 100%;
    display: block;
    position: relative;
    background-color: #dd8500;
    border: 1px solid #111111;
    padding: 0 20px 0 0;
}

.Announcments a {
    color: #111111;
}

.Announcments h5 {
    margin: 2px;
    text-align: center;
}

#Announcment-closed {
    display: none;
}

.Announcment-close-icon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
}

/* --------------------------- */
/* HEADER & NAVIGATION SECTION */
/* --------------------------- */

header {
    display: block;
    width: 100%;
    background-color: #0b0b0b;
    border-bottom: 1px solid #dd8500;
}

header .wrapper {
    max-width: 1920px;
    margin: auto;
    justify-content: space-between;
}

.logo {
    height: 50px;
    display: flex;
    color: #dd8500;
    justify-content: left;
    width: 310px;
}

.logo img {
    padding: 5px;
    width: 50px;
    object-fit: contain;
}

.logo h1 {
    margin: 0px;
}

.logo .title-full {
    display: block;
}

.logo .title-short {
    display: none;
}

header a {
    text-decoration: none;
    color: #999999;
}

header ul {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 50px;
    margin: auto;
    padding: 0;
    text-align: center;
}

header ul li {
    display: block;
    width: 150px;
    list-style: none;
    text-align: center;
}

nav {
    font-size: 1.1rem;
}

nav a, nav:visited {
    text-decoration: none;
    border: 3px solid transparent;
}

nav .active {
    border-bottom: 3px solid #dd8500;
}

nav a:hover {
    border-bottom: 3px solid #dd8500;
}

.nav-search,
.responsive-nav-search {
    color: #999999;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1rem;
    padding-right: 10px;
    
}

.nav-search input,
.responsive-nav-search input {
    border-radius: 10px;
    border: 2px solid transparent;
    background: #0b0b0b;
    background-image: url("/public/assets/icons/search-icon.svg");
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center;
    /* padding-left: 25px; */
    width: 20px;
}

.nav-search input:hover {
    border-radius: 10px;
    border: 2px solid #dd8500;
}

nav .SearchIcon {
    position: absolute;
    top: 25px;
    right: 0px;
    width: 20px;
    padding-right: 10px;
}

nav .SearchBox {
    display: none;
    position: absolute;
    top: 50px;
    right: 0px;
    width: 200px;
    height: 75px;
    background-color: #dd8500aa;
    border: 1px solid #dd8500;
    border-radius: 15px;
    z-index: 2;
}

.SearchIcon:hover .SearchBox {
    display: block;
}

.dropdown-item {
    color: #999999;
    position: relative;
    border: 3px solid transparent;
}

.dropdown-item:hover {
    border-bottom: 3px solid #dd8500;
}

.dropdown-item:hover .dropdown-content {
    display: block;
    margin-top: 0;
}

.dropdown-content {
    display: none;
    position: absolute;
    top: 26px;
    left: -3px;
    border: 1px solid black;
    background-color: #111111;
    z-index: 2;
}

.dropdown-content ul {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.dropdown-content a {
    padding: 0;
    border: 3px solid transparent;
}

@keyframes dropdown-hover {
    from {
        background-color: #111111;
        color: #999999;
    }
    to {
        background-color: #dd8500;
        color: #111111;
    }
}

.dropdown-content a:hover {
    background-color: #dd8500;
    color: #111111;
    animation: dropdown-hover;
    animation-duration: 0.5s;
    border: 3px solid transparent;
}

.dropdown-content .active {
    background-color: #dd8500;
    color: #111111;
}

.responsive-menu-icon {
    display: none;
    padding-left: 10px;
}

.responsive-nav-menu {
    display: none;
}

.responsive-nav-search {
    display: none;
}

/* --------------- */
/* SEARCH SECTION  */
/* --------------- */

.navigation-wrapper {
    display: flex;
    flex-direction: column;
    color: white;
}

.SearchBackground {
    min-height: 100vh;
    min-width: 100vw;
    background-color: #000000dd;
    position: fixed;
    top: 0;
    z-index: 10;
}

.SearchSomething {
    background-color: #111111;
    display: block;
    color: #eeeeee;
    position: fixed ;
    width: calc(60% - 20px);
    max-width: 1120px;
    left: 20%;
    overflow-y: hidden;
    z-index: 11;
    border-radius: 15px;
    padding: 10px;
    border: 1px solid #dd8500;
}

.SearchContent {
    min-height: 100px;
    max-height: 85vh;
    overflow-y: scroll;
}

.Search {
    width: calc(100% - 20px);
    max-width: 1920px;
    display:block;
    margin: 0 auto;
    padding: 10px;
}

.searchBox {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

}

.SearchSomething hr {
    border: 1px solid #eeeeee;
}

.SearchSomething input {
    margin: auto;
    text-align: center;
}

/* --------------- */
/* LOADING SECTION */
/* --------------- */

.LoadingComponent {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.LoadingSpinner {
    border: 8px solid #999999; 
    border-top: 8px solid #dd8500; 
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* --------------- */
/*   404 SECTION   */
/* --------------- */

.NotFound {
    width: calc(100% - 20px);
    max-width: 1920px;
    display:block;
    margin: auto;
    padding: 10px;
    text-align: center;
}

.NotFound h2 {
    margin-top: 20px;
}

.NotFound .Four {
    color: #dd8500;
    font-size: 5rem;
}

.NotFound .Zero {
    color: #111111;
    font-size: 5rem;
}

/* ------------ */
/* HERO SECTION */
/* ------------ */

.Hero {
    padding: 20px 10px;
    background-color: #111111;
    width: calc(100% - 20px);
    
    color: #999999;
}

.Hero .wrapper {
    width: 100%;
    max-width: 1600px;
    height: 100%;
    flex-wrap: nowrap;
    margin: auto;
    border: 1px solid transparent;
    border-radius: 15px;
    box-shadow: 0 0 5px 1px #dd8500;
}

.HeroLeft, .HeroRight {
    margin-bottom: -5px;
}

.HeroLeft {
    width: 72%;
    height: 100%;
    padding-right: 1px;
    position: relative;
}

.HeroLeft iframe {
    width: 100%;
    border: 1px solid transparent;
    height: auto;
    aspect-ratio: 16 / 9;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

.HeroLeft span {
    position: absolute;
    bottom: 50px;
    right: 10px;
    padding: 5px;
    background-color: #000000BB;
    color: #dd8500;
    border-radius: 5px;
    border: 1px solid transparent;
    font-size: 1.2rem;
    
    animation-name: hero-pop-in;
    animation-duration: 1s;
}

.HeroLeft span:hover {
    border: 1px solid #dd8500;
}

@keyframes hero-pop-in {
    from {
        transform: scale(1.2);
    }
    to {
        transform: scale(1);
    }
}

.HeroRight {
    width: 28%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 1px;
    
}

.HeroRight div {
    width: 100%;
    position: relative;
    overflow: hidden;
}

.HeroRight #active img {
    margin: 0;
    padding: 0;
    opacity: 1;
}

.HeroRight #active span {
    color: #dd8500;
}


.HeroRight img {
    width: 100%;
    max-width: 460px;
    height: auto;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;

    padding: 0 50px 0 0;
    margin: 0 0 0 -50px;
    opacity: 0.3;
    color: #999999;

    transition: 
        margin,
        padding,
        opacity;
    transition-duration: 0.5s;

}

.HeroRight img:hover {
    opacity: 1;
    padding: 0;
    margin: 0;
}

.HeroRight .HeroGameSection {
        
    position: absolute;
    font-size: 1.2rem;
    color: #999999;
    z-index: 1;
    top: 0px;
    left: 0px;
    padding: 6px;
    
    background-color: #000000BB;
    border-bottom-right-radius: 15px;
}

.HeroRight .HeroMoreInfoBtn {
    display: none;
    position: absolute;
    font-size: 1.2rem;
    color: #999999;
    bottom: 4px;
    left: 0px;
    padding: 6px;
    background-color: #000000BB;
    border-top-right-radius: 15px;
}

/* ------------------------- */
/* FRONTPAGE CONTENT SECTION */
/* ------------------------- */

.Home {
    display: block;
    width: 100%;
}

.FrontpageSection {
    max-width: 1920px;
    display:block;
    margin: auto;
    padding: 10px;
}

.FrontpageSectionTitle {
    display: flex;
    align-items: center;
    gap: 10px;
}

.FrontpageSectionTitle a {
    color: #999999;
    text-decoration: none;
}

.FrontpageSectionTitle a:hover {
    text-decoration: underline;
}

@keyframes example3 {
    from {
        transform: scale(1.1);
        box-shadow: 5px 5px 5px black;
    }
    to {
        transform: scale(1);
        box-shadow: 0 0 0 black;
    }
}

.Game {
    /* width: 460px;
    height: 215px; */
    /* 322 x 150.5 */

    width: 322px;    
    border-radius: 15px;
    position: relative;
    margin: 5px;

    animation-name: example3;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
}

.Game img {
    display: block;
    border: 1px solid black;
    border-radius: 15px;
    box-sizing: border-box;
    perspective: 1px;
    width: 100%;
}

@keyframes example {
    from {
        transform: scale(1);
        box-shadow: 0 0 0 black;
    }
    to {
        transform: scale(1.1);
        box-shadow: 5px 5px 5px black;
    }
  }
  
.Game:hover { 
    z-index: 1;
    animation-name: example;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
}

@keyframes example2 {
    from {
        color: white;
    }
    to {
        color: orange;
    }
  }

.Game:hover span {
    animation-name: example2;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;

}

.Game span {
    font-size: 1.2rem;
    color: white;
    background-color: rgb(0, 0, 0, 0.7);
    position: absolute;
    padding: 0 3px 3px 3px;
    border-radius: 5px;
}

.Game .ReleaseLabel {
    bottom: 5px;
    left: 5px;
}

.Game .EarlyAccessLabel {
    top: 5px;
    right: 5px;
}

.Game-NoThumbnail {
    padding: 5px;
}

.Game-NoThumbnail:hover {
    text-decoration: underline;
}

/* ------------------- */
/* SINGLE PAGE SECTION */
/* ------------------- */

.PageContent {
    width: calc(100% - 20px);
    max-width: 1920px;
    display:block;
    margin: 0 auto;
    padding: 10px;
}

.PageContent .wrapper {
    flex-wrap: wrap;
    justify-content: center;
}

.PageContent .Game {
    margin: 5px;
}

/* ----------------- */
/* ALL GAMES SECTION */
/* ----------------- */

.AllGames {
    width: calc(100% - 20px);
    max-width: 1920px;
    display:block;
    margin: 0 auto;
    padding: 10px;
    position: relative;
}

.AllGames .wrapper {
    flex-wrap: wrap;
    justify-content: center;
}

.AllGames ul {
    display: flex;
    flex-wrap: wrap;
    
    margin: 0 auto;
    padding: 0;
    text-align: center;
    justify-content: center;
}

.AllGames li {
    list-style: none;
    width: 30px;
    text-align: center;
    border: 1px solid black;
}

.AllGames a {
    color: #0b0b0b;
    text-decoration: none;
}

.ThumbnailButton {
    display: block;
    margin: 5px auto;
}

.AllGames .backToTopIcon {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom : 14px;
    right: 20px;
    width: 50px;
    height: 50px;
    border-radius: 30px;
    background-color: #dd8500;
    z-index: 5;
}

.backToTopIcon span {
    display: block;
    width: 0; 
    height: 0; 
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #111111;
    
}

/* .backToTopIcon span:first-child {
    transform: rotate(315deg);
}

.backToTopIcon span:last-child {
    transform: rotate(45deg);
} */

/* ----------------- */
/* RELEASED SECTION  */
/* ----------------- */

.Released {
    width: calc(100% - 20px);
    max-width: 1920px;
    display:block;
    margin: 0 auto;
    padding: 10px;
}

.Released ul {
    display: flex;
    width: 1000px;
    margin: 0 auto;
    padding: 0;
    text-align: center;
    justify-content: center;
}

.Released li {
    list-style: none;
    width: 50px;
    text-align: center;
    border: 1px solid black;
}

.Released a {
    text-decoration: none;
}

.Released button {
    margin: 5px;
    font-size: 1.1rem;
    width: 100px;
    height: 30px;
    border: 1px solid black;
    border-radius: 15px;
}

.Released button:hover {
    transform: scale(1.1);
}

/* ----------------- */
/*  CONTACT SECTION  */
/* ----------------- */

.contactPage {
    padding: 10px;
}

.flex-test {
    display: flex;
    justify-content: center;
    border: 1px solid red;
}

.contactForm {
    border: 1px solid green;
}

input, button, select {
    border-radius: 10px;
    padding: 5px;
}



/* ----------------- */
/* GAME VIEW SECTION */
/* ----------------- */

.GameView {
    display: flex;
    justify-content: center;
    /* position: relative; */
    /* width: 1210px; */
    margin: 10px auto;
    padding: 5px;
}

/* .GameView-left,
.GameView-right {
    border: 1px solid green;
} */

.ReportButton {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom : 50%;
    right: 20px;
    width: 50px;
    height: 50px;
    border-radius: 30px;
    background-color: #dd8500;
    z-index: 5;
}

.ReportContainer {
    position: fixed;
    align-items: center;
    justify-content: center;
    bottom : 25%;
    right: 80px;
    width: 300px;
    height: 500px;
    border-radius: 30px;
    background-color: #111111;
    border: 1px solid #dd8500;
    color: #eeeeee;
    z-index: 5;
    box-shadow: 3px 3px 5px 1px black;
}

.ReportContainer form {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 5px;
}

.ReportContainer form h2 {
    text-align: center;
    color: #dd8500;
}

.ReportContainer form p {
    text-align: center;
}

.ReportContainer textarea {
    width: 290px;
    height: 200px;
    box-sizing: border-box;
    resize: none;
    border-radius: 10px;
    padding: 5px;
}

.GameView #show {
    display: flex;
}

.GameView #hide {
    display: none;
}

.GameView h2, .GameView h3 {
    margin: 2px 0;
    padding: 0px;
}

.GameView-left img,
.GameView-right iframe {
    border-radius: 15px;
}

.GameView-left img {
    width: 100%;
    max-width: 460px;
}

.GameView iframe {
    width: 100%;
    max-width: 1000px;
    height: auto;
    aspect-ratio: 16 / 9;
    border: 1px solid black;
    box-shadow: 3px 3px 3px black;
}

.GameView-right span {
    display: block;
    width: 100%;
    max-width: 1000px;
}

.GameView-left img {
    border: 1px solid black;
    box-shadow: 3px 3px 3px black;
    margin-top: 11px;
}

.GameView a,
.GameView a:visited {
    color: #dd8500;
    text-decoration: none;
}

.GameView-left a:hover {
    text-decoration: underline;
}

.GameView-left, .GameView-right {
    padding: 5px;
    margin: 1px;
}

.GameSocialMedia {
    display: flex;
    flex-wrap: wrap;
}

.GameSocialMedia li {
    list-style: none;
}

.GameSocialMedia .social-icon:hover {
    transform: scale(1.2);
}

.GamePlatforms {
    display: flex;
}

.GamePlatforms li {
    list-style: none;
}

.GamePlatforms img {
    width: 32px;
    padding: 0 10px;
}

.GamePlatforms img:hover {
    transform: scale(1.2);
}

/* ----------------- */
/* Changelog SECTION */
/* ----------------- */

.ChangelogPage {
    width: calc(100% - 20px);
    max-width: 1920px;
    display:block;
    margin: 0 auto;
    padding: 10px;
    position: relative;
}

.ChangelogPage h3 {
    margin: 0;
}

.ChangelogPage card {
    padding: 5px;
    width: 80%;
}

/* -------------- */
/* SUBMIT SECTION */
/* -------------- */

.SubmitGamePage {
    width: calc(100% - 20px);
    max-width: 1920px;
    display:block;
    margin: 0 auto;
    padding: 10px;
}

.SubmitGameContainer {
    width: 50%;
    margin: auto;
    padding: 10px;
}

.formPageTitleContainer {
    display: flex;
    border-bottom: 1px solid black;
    padding: 0 5px;
    margin: 10px 0;
}

.formPageTitleContainer ul {
    display: flex;
    width: 100%;
    margin: 0;
    padding: 0;
    justify-content: flex-start;
    gap: 5px;
}

.formPageTitleContainer li {
    padding: 0 5px;
    margin-bottom: -1px;
    font-size: 20px;
    color: grey ;
    list-style: none;
    border-top: 1px solid black;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    cursor: not-allowed;
}

.formPageTitleContainer li::after {
    content: ' ❌';
}

.formPageTitleContainer .active {
    color: black;
    border-bottom: 1px solid #eeeeee;
    cursor: default;
}

.formPageTitleContainer .done {
    color: black;
    cursor: pointer;
}

.formPageTitleContainer .done::after {
    content: ' ✅';
}

.test {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 5px;
}

.SubmitGameContainer .error {
    border: 1px solid red;
}
.SubmitGameContainer .errorMessage {
   color: red;
}

.SubmitGameContainer textarea {
    height: 200px;
    border-radius: 10px;
    padding: 5px;
}

.formButtonsContainer {
    display: flex;
    justify-content: center;
    gap: 5px;
    margin-top: 10px;
}

/* -------------- */
/* FOOTER SECTION */
/* -------------- */

footer {
    color: #999999;
    background-color: #111111;
    width: 100%;
    padding-top: 10px;
    text-align: center;
    border-top: 1px solid #dd8500;
    margin-top: auto;
}

footer h2 {
    color: #dd8500;
    margin: 0 10px;
    padding: 5px 0;
    /* border-bottom: 1px solid #dd8500; */
}

footer .wrapper {
    flex-wrap: wrap;
    max-width: 1920px;
    margin: auto;
}

.footerContent {
    display: block;
    width: 25%;
    height: 150px;
}

.footerContent img {
    height: 90%;
    padding: 5px;
}

.footerContent ul {
    padding: 0px;
    list-style: none;
}

.footerContent a {
    /* color: #dd8500; */
    color: #999999;
    text-decoration: none;
}

.footerContent a:hover {
    text-decoration: underline;
}

footer .social-icon {
    margin: 5px;
    
}

footer .social-icon:hover {
    transform: scale(1.2);
}

.copyright {
    border-top: 1px solid black;
    padding: 2px 0;
    background-color: #0b0b0b;
}

.copyright a {
    color: #dd8500;
    text-decoration: none;
}

.copyright a:hover {
    text-decoration: underline;
}

/* ------------------ */
/* RESPONSIVE SECTION */
/* ------------------ */

@media (max-width: 1150px) {
    .logo {
        width: 110px;
    }
    
    .logo .title-full {
        display: none;
    }
    
    .logo .title-short {
        display: block;
    }
}

@media (max-width: 1015px) {
    header {
        position: fixed;
        z-index: 2;
    }

    main {
        margin-top: 50px;
    }

    nav ul {
        display: none;
    }
    
    .responsive-menu-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50px;
        height: 50px;
        padding-right: 10px;
    }
    
    .responsive-menu-icon span {
        width: 45px;
        height: 7px;
        border-top: 3px solid #dd8500;
    }
    
    .responsive-nav-menu {
        position: fixed;
        right: 0px;
        
        width: 100%;
        height: 100%;
        z-index: 2;
        background-color: #111111dd;
    }

    @keyframes burger-menu-open {
        from {
            display:none;
            height: 0%;
            opacity: 0;
        }
        to {
            height: 100%;
            opacity: 1;
        }
      }

      @keyframes burger-menu-close {
        from {
            display: block;
            height: 100%;
            opacity: 1;
        }
        to {
            height: 0%;
            opacity: 0;
            
        }
      }

    .responsive-nav-search {
        display: flex;
    }

    #open, 
    #open ul {
        
        animation-name: burger-menu-open;
        animation-duration: 0.7s;
        display: block;
    }

    #closed,
    #closed ul {
        
        animation-name: burger-menu-close;
        animation-duration: 0.7s;
        display: none;
    }

    .responsive-nav-menu ul {
        height: 100%;
    }

    .responsive-nav-menu li {
        width: 240px;
        padding-left: 15px;
        margin: 5px 0;
        text-align: left;
        color: #999999;
    }

    .responsive-nav-menu hr {
        margin: 0 10px;
        border: 1px solid #999999;
    }

    .responsive-menu-icon li ul {
        border-bottom: 1px solid green;
    }

    .responsive-nav-menu a {
        display: block;
        border: 0px solid transparent
    }

    .responsive-nav-menu a:hover {
        background-color: #dd8500;
        border-bottom: 0px solid transparent;
    }

    .responsive-nav-menu .active {
        color: #dd8500;
    }

    .SearchSomething {
        width: calc(80% - 20px);
        left: 10%;
    }

    .Hero .wrapper {
        flex-direction: column;
    }

    .Hero .HeroRight,
    .Hero .HeroLeft {
        width: 100%;
    }

    .Hero .HeroRight {
        flex-direction: row;
    }

    .HeroLeft {
        margin-bottom: -6px;
    }

    .HeroLeft iframe {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    .HeroRight img {
        padding: 0 0 30px 0;
        margin: -30px 0 0 0;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
    }

    .GameView {
        display: block;
    }

    .GameView img {
        display: block;
        margin: auto;
    }

    .footerContent {
        width: 50%;
    }
}

@media (max-width: 600px) {
    
    #noThumbnail {
        margin-left: 0;
    }

    .SearchSomething {
        width: calc(99% - 20px);
        left: 0;
    }

    .HeroRight div {
        position: relative;
    }
    
    .HeroRight img {
        padding: 0 0 10px 0;
        margin: -10px 0 0 0;
    }

    .HeroRight div .HeroGameSection {
        display: block;
        position: relative;
        overflow: hidden;
        height: 52px;
        background-color: transparent;
        border-top: 1px solid #dd8500;
        border-bottom: 1px solid #dd8500;
        border-radius: 0;
        background-color: #111111;
        
        z-index: 1;
    }

    .footerContent {
        width: 100%;
    }
}