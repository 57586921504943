.loginpage {
    width: 100%;
    flex-grow: 1;
    max-width: 1920px;
    display: flex;
    align-items: center;
    justify-content: center;

    .login {
        width: 500px;
        height: 300px;
        border-radius: 15px;
        padding: 20px;
        border: 1px solid black;
        display: flex;
        justify-content: center;
        align-items: center;

        form {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 5px;
        }

        h2 {
            padding: 0;
            text-align: center;
        }
    }
}

.dashboard {
    width: 100%;
    flex-grow: 1;
    max-width: 1920px;
    display: flex;
    margin: 0 auto;

    .wrapper {
        min-height: 100%;
    }

    .dashboard-nav {
        width: 15%;
        min-height: 100%;
        background-color: #111111;
        color: #999999;
        
        h3 {
            text-align: center;
        }
        

        ul {
            display: flex;
            flex-direction: column;
            padding: 0;
        }

        li {
            list-style: none;
            height: 30px;
            margin: 0 15px;
            border-bottom: 1px solid #999999;
            display: flex;
            align-items: center;

            a, a:visited {
                color: #999999;
                text-decoration: none;
            }

            a:hover {
                color: #dd8500;
            }

            a:active {
                color: #dd8500;
            }
        }
    }
    
    .dashboard-content {
        width: calc(85% - 20px);
        height: 100%;
        height: 100%;
        padding: 10px;
        // overflow-y: scroll;
        overflow-x: scroll;

        table {
            width: 100%;

            thead {
                background-color: #111111;
                color: #dd8500;
                text-align: left;
            }

            tbody:nth-child(even) {
                background-color: #cccccc;
            }

            th, td {
                padding-left: 5px;
            }
        }

        .fullEditContainer {
            border: 1px solid red;
            height: 100px;
        }

        #open {
            display: block;
        }
        #closed {
            display: none;
        }
    }

    .ReportMenu {
        display: flex;
        justify-content: left;
        border-bottom: 1px solid black;
        margin-bottom: 5px;

        div {
            border-top: 1px solid black;
            border-right: 1px solid black;
            border-left: 1px solid black;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            padding: 5px;
            margin-right: 3px;
            margin-bottom: -1px;
            background-color: #999999;
            
            :hover {
                color: #dd8500;
            }
        }

        .active {
            border-bottom: 1px solid #EEEEEE;
            background-color: #EEEEEE;
        }
    }

    .AddNewGameContainer {
        width: 50%;
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
}